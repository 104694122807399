<script setup lang="ts">
const goals = [
  {
    id: 1,
    number: 1,
    title: 'No Poverty',
    url: 'https://www.undp.org/sustainable-development-goals/no-poverty',
    color: '#e5243b',
  },
  {
    id: 2,
    number: 2,
    title: 'Zero Hunger',
    url: 'https://www.undp.org/sustainable-development-goals/zero-hunger',
    color: '#dda63a',
  },
  {
    id: 3,
    number: 3,
    title: 'Good Health and Well-being',
    url: 'https://www.undp.org/sustainable-development-goals/good-health-and-well-being',
    color: '#4c9f38',
  },
  {
    id: 4,
    number: 4,
    title: 'Quality Education',
    url: 'https://www.undp.org/sustainable-development-goals/quality-education',
    color: '#c5192d',
  },
  {
    id: 5,
    number: 5,
    title: 'Gender Equality',
    url: 'https://www.undp.org/sustainable-development-goals/gender-equality',
    color: '#ff3a21',
  },
  {
    id: 6,
    number: 6,
    title: 'Clean Water and Sanitation',
    url: 'https://www.undp.org/sustainable-development-goals/clean-water-and-sanitation',
    color: '#26bde2',
  },
  {
    id: 7,
    number: 7,
    title: 'Affordable and Clean Energy',
    url: 'https://www.undp.org/sustainable-development-goals/affordable-and-clean-energy',
    color: '#fcc30b',
  },
  {
    id: 8,
    number: 8,
    title: 'Decent Work and Economic Growth',
    url: 'https://www.undp.org/sustainable-development-goals/decent-work-and-economic-growth',
    color: '#a21942',
  },
  {
    id: 9,
    number: 9,
    title: 'Industry, Innovation and Infrastructure',
    url: 'https://www.undp.org/sustainable-development-goals/industry-innovation-and-infrastructure',
    color: '#fd6925',
  },
  {
    id: 10,
    number: 10,
    title: 'Reduced Inequalities',
    url: 'https://www.undp.org/sustainable-development-goals/reduced-inequalities',
    color: '#dd1367',
  },
  {
    id: 11,
    number: 11,
    title: 'Sustainable Cities and Communities',
    url: 'https://www.undp.org/sustainable-development-goals/sustainable-cities-and-communities',
    color: '#fd9d24',
  },
  {
    id: 12,
    number: 12,
    title: 'Responsible Consumption and Production',
    url: 'https://www.undp.org/sustainable-development-goals/responsible-consumption-and-production',
    color: '#bf8b2e',
  },
  {
    id: 13,
    number: 13,
    title: 'Climate Action',
    url: 'https://www.undp.org/sustainable-development-goals/climate-action',
    color: '#3f7e44',
  },
  {
    id: 14,
    number: 14,
    title: 'Life Below Water',
    url: 'https://www.undp.org/sustainable-development-goals/life-below-water',
    color: '#0a97d9',
  },
  {
    id: 15,
    number: 15,
    title: 'Life on Land',
    url: 'https://www.undp.org/sustainable-development-goals/life-on-land',
    color: '#56c02b',
  },
  {
    id: 16,
    number: 16,
    title: 'Peace, Justice and Strong Institutions',
    url: 'https://www.undp.org/sustainable-development-goals/peace-justice-and-strong-institutions',
    color: '#00689d',
  },
  {
    id: 17,
    number: 17,
    title: 'Partnerships for the Goals',
    url: 'https://www.undp.org/sustainable-development-goals/partnerships-for-the-goals',
    color: '#19486a',
  },
]
const props = defineProps({
  category: {
    type: String,
  },
  color: {
    type: String,
    default: '#fff',
  },
})

const categoryNumber = computed(() => {
  if (!props.category) return null
  const categoryNumber = props.category!.split(' ')[1]
  return categoryNumber
})

const selectedCategory = computed(() => {
  if (!categoryNumber.value)
    return {
      title: 'No category',
      color: '#fff',
      url: 'https://www.undp.org/sustainable-development-goals',
    }
  return goals.find((goal) => goal.number === Number(categoryNumber.value))
})
</script>

<template>
  <div
    v-if="selectedCategory"
    class="inline-block px-2 py-1 rounded text-center z-100 bg-[#f6f6f6]"
  >
    <a
      rel="noopener noreferrer"
      :href="selectedCategory.url"
      target="_blank"
      class="inline-block uppercase z-100 relative font-base text-white font-medium"
    >
      <span
        class="inline-block sdg-tag uppercase font-base text-sm font-medium"
        :style="{ color: color }"
      >
        {{ category }}
      </span>
    </a>
  </div>
</template>

<style scoped lang="scss">
.sdg-tag {
  &:hover {
    color: v-bind('selectedCategory?.color') !important;
  }
}
</style>
